import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { RestrictedRoute } from "./RestrictedRoute";
import SessionErrorPage from "../pages/SessionErrorPage";
import { ApplicationHome } from "../pages/Application";
import useAuth from "../hooks/authHook";
import { AuthenticationPage } from "../pages/Authentication/";

const MainNavigation: React.FC<any> = () => {
	const { isLoggedIn } = useAuth();

	return (
		<Router>
			<Switch>
				<RestrictedRoute
					path="/application/:id"
					component={ApplicationHome}
					isLoggedIn={isLoggedIn}
				/>
				<Route path="/authenticate" component={AuthenticationPage} />
				<Route component={SessionErrorPage} />
			</Switch>
		</Router>
	);
};

export default MainNavigation;
