import React from "react";
import DetailsReadOnly from "./DetailsReadOnly";
import CollapsibleCard from "../components/CollapsibleCard";
import ApplicantUlpForm from "../Applicant/Applicant.ULP.Form";
import useApplication from "../../../hooks/applicationHook";
import { Mapper } from "./mapper";

const LoanDetails: React.FC = () => {
	const { application } = useApplication();

	return (
		<div className="loan-step-content__loan-details" data-test-id="loan-details__root-view">
			<CollapsibleCard
                testId="loan-details__collapsible-card"
				title="Loan Details"
				desc="Details about your course and loan"
				isCollapsed={true}
				isLocked={false}
				defaultIsEditing={false}
				showEdit={false}
				onToggleCollapse={() => {}}
				renderReadOnlyView={
					<DetailsReadOnly
						data={Mapper.MapLoanDetailsCardModel(application!)}
					/>
				}
				renderFormView={<> </>}
			/>
		</div>
	);
};

export default LoanDetails;
