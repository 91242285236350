import { apiRequestHandler, getMiddleware } from "../middlewareHelper";
import { actions as types } from "./actions";
import { showError } from "../ui/actions";
import service from "../../services/application";
import { toast } from "react-toastify";

export const fetchApplicationByQuoteFlow = getMiddleware({
	actionType: types.FETCH_APPLICATION_BY_QUOTE,
	onRequest: apiRequestHandler(service.getApplicationByQuoteId),
});

export const fetchPanDetailsFlow = getMiddleware({
	actionType: types.FETCH_PAN_DETAILS,
	onRequest: apiRequestHandler(service.getPanDetailsForApplication),
	onError: async (opts) => {
		toast(opts.error.message, {
            type: "error",
            position: "top-center",
        });
        opts.dispatch(
            showError(
                "field",
                "Pan Error",
                opts.error ? opts.error.message : "Invalid PAN",
                types.FETCH_PAN_DETAILS,
                {
                    field: "pan",
                }
            )
        );
	}
});

export const fetchPincodeDetailsFlow = getMiddleware({
	actionType: types.FETCH_PINCODE_DETAILS,
	onRequest: apiRequestHandler(service.getPincodeDetailsForApplication),
});

export const updateApplicantFlow = getMiddleware({
	actionType: types.UPDATE_APPLICANT,
	onRequest: apiRequestHandler(service.updateApplicant),
});

export const updateBankAccountDetailsFlow = getMiddleware({
	actionType: types.UPDATE_BANK_ACCOUNT_DETAILS,
	onRequest: apiRequestHandler(service.updateBankAccountDetails),
});

export const extractAddressFlow = getMiddleware({
	actionType: types.EXTRACT_ADDRESS_PROOF_DETAILS,
	onRequest: apiRequestHandler(service.extractAddressProofDetails),
});

export const verifyUpdateAddressFlow = getMiddleware({
	actionType: types.VERIFY_UPDATE_ADDRRESS_PROOF_DETAILS,
	onRequest: apiRequestHandler(service.verifyUpdateAddressDetails),
});

export const extractPanFlow = getMiddleware({
	actionType: types.EXTRACT_PAN_PROOF_DETAILS,
	onRequest: apiRequestHandler(service.extractPanProofDetails),
});

export const sendApplicationFlow = getMiddleware({
	actionType: types.SEND_APPLICATION_LINK,
	onRequest: apiRequestHandler(service.sendApplicationLink),
});

export const fetchUploadedBankStatementsFlow = getMiddleware({
	actionType: types.FETCH_UPLOADED_BANK_STATEMENTS,
	onRequest: apiRequestHandler(service.getUploadedBankStatement),
});

export const fetchIfscDetailsFlow = getMiddleware({
	actionType: types.FETCH_IFSC_DETAILS,
	onRequest: apiRequestHandler(service.getIfscDetails),
});

export const extractBankStatementFlow = getMiddleware({
	actionType: types.EXTRACT_BANK_STATEMENT_DETAILS,
	onRequest: apiRequestHandler(service.extractBankStatementDetails),
});

export const fetchUploadedIncomeProofsFlow = getMiddleware({
	actionType: types.FETCH_UPLOADED_INCOME_PROOFS,
	onRequest: apiRequestHandler(service.getUploadedIncomeProofs),
});

export const fetchUploadedMiscDocsFlow = getMiddleware({
	actionType: types.FETCH_UPLOADED_MISC_DOCS,
	onRequest: apiRequestHandler(service.getUploadedMiscDocs),
});

export default [
	fetchApplicationByQuoteFlow,
	fetchPanDetailsFlow,
	fetchPincodeDetailsFlow,
	updateApplicantFlow,
	updateBankAccountDetailsFlow,
	extractAddressFlow,
	verifyUpdateAddressFlow,
	extractPanFlow,
	sendApplicationFlow,
	fetchUploadedBankStatementsFlow,
	fetchIfscDetailsFlow,
	extractBankStatementFlow,
	fetchUploadedIncomeProofsFlow,
    fetchUploadedMiscDocsFlow
];
