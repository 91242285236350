import serviceDev from './service.dev';
import serviceProduction from './service.prod';
import serviceStaging from './service.staging';
import serviceKarma from "./service.karma";
import { ENV } from '../../env.json';

let config = serviceDev;

if (ENV === 'dev')
{
    config = serviceDev;
} else if (ENV === 'staging')
{
    config = serviceStaging;
} else if (ENV === 'karma')
{
    config = serviceKarma;
} else
{
    config = serviceProduction;
}

export default config;
