import
    {
        Application,
        ApplicationCreation,
    } from "../../../services/application/models/Application";

export class ApplicationHelper
{
    private application?: ApplicationCreation;

    constructor (application: ApplicationCreation | null)
    {
        this.application = application ? application : undefined;
    }

    getSectionToShow(): "application-completion" | "loan-documentation"
    {
        if (!this.application)
        {
            return "application-completion";
        } else
        {
            return "application-completion";
        }
    }

    isKycDocumentsUploaded(): boolean
    {
        let uploaded = false;

        if (!this.application)
        {
            return uploaded;
        }

        const applicantPanUploaded =
            this.isApplicantPANRequired() && this.isApplicantPANUploaded();
        const coapplicantPanUploaded =
            this.isCoApplicantPANRequired() && this.isCoApplicantPANUploaded();
        const applicantAddressProofUploaded =
            this.isApplicantAddressProofRequired() &&
            this.isApplicantAddressProofUploaded();
        const coapplicantAddressProofUploaded =
            this.isCoApplicantAddressProofRequired() &&
            this.isCoApplicantAddressProofUploaded();
        const studentMarksListsUploaded = this.isStudentMarksListsRequired()
            ? this.isStudentMarksListsUploaded()
            : true;

        if (this.application.CoBorrowerRequired)
        {
            uploaded =
                applicantPanUploaded &&
                coapplicantPanUploaded &&
                applicantAddressProofUploaded &&
                coapplicantAddressProofUploaded &&
                studentMarksListsUploaded;
        } else
        {
            uploaded =
                applicantPanUploaded &&
                applicantAddressProofUploaded &&
                studentMarksListsUploaded;
        }

        return uploaded;
    }

    isAllDocumentUploaded(): boolean
    {
        let uploaded = false;

        if (!this.application)
        {
            return uploaded;
        }

        const applicantPanUploaded =
            this.isApplicantPANRequired() && this.isApplicantPANUploaded();
        const coapplicantPanUploaded =
            this.isCoApplicantPANRequired() && this.isCoApplicantPANUploaded();
        const applicantAddressProofUploaded =
            this.isApplicantAddressProofRequired() &&
            this.isApplicantAddressProofUploaded();
        const coapplicantAddressProofUploaded =
            this.isCoApplicantAddressProofRequired() &&
            this.isCoApplicantAddressProofUploaded();
        // const applicantIncomeProofUploaded = this.isApplicantIncomeProofRequired() && this.isApplicantIncomeProofUploaded();
        // const coApplicantIncomeProofUploaded = this.isCoApplicantIncomeProofRequired() && this.isCoApplicantAddressProofUploaded();

        if (this.application.CoBorrowerRequired)
        {
            uploaded =
                applicantPanUploaded &&
                coapplicantPanUploaded &&
                applicantAddressProofUploaded &&
                coapplicantAddressProofUploaded;
        } else
        {
            uploaded = applicantPanUploaded && applicantAddressProofUploaded;
        }

        return uploaded;
    }

    isApplicantDetailsCompleted():boolean
    {
        let completed = false;

        if (!this.application)
        {
            return completed;
        }

        if(this.application.PersonalDetails.Applicant.Captured)
        {
            completed =true;
        }

        return completed
    }

    isCoApplicantPANUploaded(): boolean
    {
        let captured = false;

        if (!this.application)
        {
            captured = false;
        } else
        {
            if (this.application.PANDocument.CoApplicant)
            {
                if (
                    this.application.PANDocument.CoApplicant.Required &&
                    this.application.PANDocument.CoApplicant.Captured &&
                    !this.application.PANDocument.CoApplicant.Error
                )
                {
                    captured = true;
                }
            } else
            {
                captured = false;
            }
        }

        return captured;
    }

    isCoApplicantPANRequired()
    {
        return this.application?.PANDocument.CoApplicant?.Required ?? false;
    }

    isApplicantPANRequired()
    {
        return this.application?.PANDocument.Applicant.Required ?? false;
    }

    isStudentMarksListsRequired()
    {
        return this.application?.MarksLists.Applicant.Required ?? false;
    }

    isStudentMarksListsUploaded()
    {
        let captured = false;

        if (!this.application)
        {
            captured = false;
        } else
        {
            if (
                this.application.MarksLists.Applicant.Required &&
                this.application.MarksLists.Applicant.Captured &&
                !this.application.MarksLists.Applicant.Error
            )
            {
                captured = true;
            }
        }

        return captured;
    }

    isApplicantPANUploaded()
    {
        let captured = false;

        if (!this.application)
        {
            captured = false;
        } else
        {
            if (
                this.application.PANDocument.Applicant.Required &&
                this.application.PANDocument.Applicant.Captured &&
                !this.application.PANDocument.Applicant.Error
            )
            {
                captured = true;
            }
        }

        return captured;
    }

    isApplicantAddressProofUploaded()
    {
        let captured = false;

        if (!this.application)
        {
            captured = false;
        } else
        {
            if (
                this.application.AddressProofDocument.Applicant.Required &&
                this.application.AddressProofDocument.Applicant.Captured &&
                !this.application.AddressProofDocument.Applicant.Error
            )
            {
                captured = true;
            }
        }

        return captured;
    }

    isCoApplicantAddressProofUploaded()
    {
        let captured = false;

        if (!this.application)
        {
            captured = false;
        } else
        {
            if (this.application.AddressProofDocument.CoApplicant)
            {
                if (
                    this.application.AddressProofDocument.CoApplicant
                        .Required &&
                    this.application.AddressProofDocument.CoApplicant
                        .Captured &&
                    !this.application.AddressProofDocument.CoApplicant.Error
                )
                {
                    captured = true;
                }
            } else
            {
                captured = false;
            }
        }

        return captured;
    }

    isCoApplicantAddressProofRequired()
    {
        return (
            this.application?.AddressProofDocument.CoApplicant?.Required ??
            false
        );
    }

    isApplicantAddressProofRequired()
    {
        return (
            this.application?.AddressProofDocument.Applicant.Required ?? false
        );
    }

    // isApplicantIncomeProofUploaded()
    // {
    //     let captured = false;

    //     if (!this.application)
    //     {
    //         captured = false;
    //     }
    //     else
    //     {
    //         if (this.application?.IncomeProof?.Applicant.Required &&
    //             this.application?.IncomeProof?.Applicant.Captured &&
    //             !this.application?.IncomeProof?.Applicant.Error)
    //         {
    //             captured = true;
    //         }
    //     }

    //     return captured;
    // }

    // isCoApplicantIncomeProofUploaded()
    // {
    //     let captured = false;

    //     if (!this.application)
    //     {
    //         captured = false;
    //     }
    //     else
    //     {
    //         if (this.application.IncomeProof.CoApplicant)
    //         {
    //             if (this.application.IncomeProof.CoApplicant.Required &&
    //                 this.application.IncomeProof.CoApplicant.Captured &&
    //                 !this.application.IncomeProof.CoApplicant.Error)
    //             {
    //                 captured = true;
    //             }
    //         }
    //         else
    //         {
    //             captured = false;
    //         }
    //     }

    //     return captured;
    // }

    // isCoApplicantIncomeProofRequired()
    // {
    //     return this.application?.IncomeProof?.CoApplicant?.Required ?? false;
    // }

    // isApplicantIncomeProofRequired()
    // {
    //     return this.application?.IncomeProof?.Applicant.Required ?? false;
    // }
}
