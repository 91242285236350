import { useSelector } from 'react-redux';
import { RootState } from '../config/store';

function useActionLoader()
{
    const spinners = useSelector((state: RootState) => state.ui.spinners);

    return { ...spinners };
}

export default useActionLoader;
