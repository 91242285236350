export const StateList = [
	{
        "Id": 1,
        "value": "ANDAMAN AND NICOBAR ISLANDS",
        "label": "ANDAMAN AND NICOBAR ISLANDS",
        "Code": "AN"
    },
    {
        "Id": 2,
        "value": "ANDHRA PRADESH",
        "label": "ANDHRA PRADESH",
        "Code": "AP"
    },
    {
        "Id": 3,
        "value": "ARUNACHAL PRADESH",
        "label": "ARUNACHAL PRADESH",
        "Code": "AR"
    },
    {
        "Id": 4,
        "value": "ASSAM",
        "label": "ASSAM",
        "Code": "AS"
    },
    {
        "Id": 5,
        "value": "BIHAR",
        "label": "BIHAR",
        "Code": "BH"
    },
    {
        "Id": 6,
        "value": "CHANDIGARH",
        "label": "CHANDIGARH",
        "Code": "CH"
    },
    {
        "Id": 7,
        "value": "CHHATTISGARH",
        "label": "CHHATTISGARH",
        "Code": "CT"
    },
    {
        "Id": 8,
        "value": "DAMAN AND DIU",
        "label": "DAMAN AND DIU",
        "Code": "DD"
    },
    {
        "Id": 9,
        "value": "DELHI",
        "label": "DELHI",
        "Code": "DL"
    },
    {
        "Id": 10,
        "value": "DADRA AND NAGAR HAVELI",
        "label": "DADRA AND NAGAR HAVELI",
        "Code": "DN"
    },
    {
        "Id": 11,
        "value": "GOA",
        "label": "GOA",
        "Code": "GA"
    },
    {
        "Id": 12,
        "value": "GUJARAT",
        "label": "GUJARAT",
        "Code": "GJ"
    },
    {
        "Id": 13,
        "value": "HIMACHAL PRADESH",
        "label": "HIMACHAL PRADESH",
        "Code": "HP"
    },
    {
        "Id": 14,
        "value": "HARYANA",
        "label": "HARYANA",
        "Code": "HR"
    },
    {
        "Id": 15,
        "value": "JHARKHAND",
        "label": "JHARKHAND",
        "Code": "JH"
    },
    {
        "Id": 16,
        "value": "JAMMU AND KASHMIR",
        "label": "JAMMU AND KASHMIR",
        "Code": "JK"
    },
    {
        "Id": 17,
        "value": "KARNATAKA",
        "label": "KARNATAKA",
        "Code": "KA"
    },
    {
        "Id": 18,
        "value": "KERALA",
        "label": "KERALA",
        "Code": "KL"
    },
    {
        "Id": 19,
        "value": "LAKSHADWEEP",
        "label": "LAKSHADWEEP",
        "Code": "LD"
    },
    {
        "Id": 20,
        "value": "MEGHALAYA",
        "label": "MEGHALAYA",
        "Code": "ME"
    },
    {
        "Id": 21,
        "value": "MAHARASHTRA",
        "label": "MAHARASHTRA",
        "Code": "MH"
    },
    {
        "Id": 22,
        "value": "MIZORAM",
        "label": "MIZORAM",
        "Code": "MI"
    },
    {
        "Id": 23,
        "value": "MANIPUR",
        "label": "MANIPUR",
        "Code": "MN"
    },
    {
        "Id": 24,
        "value": "MADHYA PRADESH",
        "label": "MADHYA PRADESH",
        "Code": "MP"
    },
    {
        "Id": 25,
        "value": "NAGALAND",
        "label": "NAGALAND",
        "Code": "NL"
    },
    {
        "Id": 26,
        "value": "ODISHA",
        "label": "ODISHA",
        "Code": "OR"
    },
    {
        "Id": 27,
        "value": "OTHERS",
        "label": "OTHERS",
        "Code": "OT"
    },
    {
        "Id": 28,
        "value": "PUNJAB",
        "label": "PUNJAB",
        "Code": "PB"
    },
    {
        "Id": 29,
        "value": "PUDUCHERRY",
        "label": "PUDUCHERRY",
        "Code": "PY"
    },
    {
        "Id": 30,
        "value": "RAJASTHAN",
        "label": "RAJASTHAN",
        "Code": "RJ"
    },
    {
        "Id": 31,
        "value": "SIKKIM",
        "label": "SIKKIM",
        "Code": "SK"
    },
    {
        "Id": 32,
        "value": "TELANGANA",
        "label": "TELANGANA",
        "Code": "TL"
    },
    {
        "Id": 33,
        "value": "TAMIL NADU",
        "label": "TAMIL NADU",
        "Code": "TN"
    },
    {
        "Id": 34,
        "value": "TRIPURA",
        "label": "TRIPURA",
        "Code": "TR"
    },
    {
        "Id": 35,
        "value": "UTTAR PRADESH",
        "label": "UTTAR PRADESH",
        "Code": "UP"
    },
    {
        "Id": 36,
        "value": "UTTARAKHAND",
        "label": "UTTARAKHAND",
        "Code": "UT"
    },
    {
        "Id": 37,
        "value": "WEST BENGAL",
        "label": "WEST BENGAL",
        "Code": "WB"
    },
    {
        "Id": 38,
        "value": "OKLAHOMA",
        "label": "OKLAHOMA",
        "Code": "OKL"
    },
    {
        "Id": 39,
        "value": "LADAKH",
        "label": "LADAKH",
        "Code": "LA"
    }
];
