import React from "react";
import "./style.scss";

type Props = {
	label: string;
	value: string;
};

const TextView: React.FC<Props> = (props) => {
	return (
		<div className="nimbus_text-view">
			<div className="nimbus_text-view__label">{props.label}</div>
			<div className="nimbus_text-view__value">{props.value}</div>
		</div>
	);
};

export default TextView;
