export const GetGenderLabel = (gender: string) =>
{
    if (gender === "male")
    {
        return "Male";
    }
    else if (gender === "female")
    {
        return "Female";
    }
    else
    {
        return "Other"
    }
}