import React from "react";

const SessionErrorPage: React.FC = () => {
	return (
		<div style={{ padding: 36 }}>
			<h3>Session Expired or Invalid. </h3>
		</div>
	);
};

export default SessionErrorPage;
