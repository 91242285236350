import React from "react";

type Props = {};

type RowGridProps = {};
type ColGridProps = {};

const RowGrid: React.FC<RowGridProps> = (props) => {
	return (
		<div
			style={{
				display: "flex",
				padding: 16,
				// border: "1px solid #ccc",
			}}
		>
			{props.children}
		</div>
	);
};

const ColGrid: React.FC<ColGridProps> = (props) => {
	return (
		<div
			style={{
				flex: 1,
				// border: "1px solid red",
			}}
		>
			{props.children}
		</div>
	);
};

const NGrid: React.FC<Props> & {
	Row: typeof RowGrid;
	Col: typeof ColGrid;
} = (props) => {
	return <>{props.children}</>;
};

NGrid.Col = ColGrid;
NGrid.Row = RowGrid;

export default NGrid;
