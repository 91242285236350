import React from "react";
import useApplication from "../../../hooks/applicationHook";
import ApplicantRoot from "../Applicant/ApplicantRoot";
import BankDetailsRoot from "../BankDetails/BankDetailsRoot";
import DocumentUploadRoot from "../DocumentUpload/DocumentUploadRoot";
import LoanDetails from "../LoanDetails";
import SendLinkRoot from "../SendLink/SendLinkRoot";
import Result from "./Result";
import FinanceDocumentsUploadRoot from "../FinanceDocumentsUpload/FinanceDocumentsUploadRoot"

const LoanSteps: React.FC = () => {
	const { application } = useApplication();

	if (Number(application?.ApplicationReference) > 0) {
		return (
			<div className="loan-steps-container">
				<div className="loan-step-content loan-step-content__result-container">
					<Result
                        testId="loan-step-content__result"
						status="success"
						title="Congratulations!"
						subTitle="You have completed your loan application! We are processing it and will get back to you soon."
					/>
				</div>
			</div>
		);
	}

	return (
		<div>
			<div className="loan-step-content">
				{!application?.Flags?.IsCounsellorSentLinkToCustomer && (
					<React.Fragment>
						<LoanDetails />
						<ApplicantRoot />
						{/* <DocumentUploadRoot />
                        <FinanceDocumentsUploadRoot />
						<BankDetailsRoot /> */}
					</React.Fragment>
				)}
				<SendLinkRoot />
			</div>
		</div>
	);
};

export default LoanSteps;
