import { createAction } from "@reduxjs/toolkit";
import { GetPanDetailsRequest } from "../../services/application/models/GetPanDetailsRequest";
import { GetPincodeDetailsRequest } from "../../services/application/models/GetPincodeDetailsRequest";
import { ApplicantUpdateReq } from "../../services/application/models/ApplicantUpdateReq";
import { actionRequest } from "../helper";
import { UpdateBankAccountDetailsReq } from "../../services/application/models/UpdateBankAccountDetailsReq";
import { ExtractedAddressProofDetailsReq } from "../../services/application/models/ExtractedAddressProofDetailsReq";
import { VerifyUpdateAddressDetailsReq } from "../../services/application/models/VerifyUpdateAddressDetailsReq";
import { ExtractedPanProofDetailsReq } from "../../services/application/models/ExtractedPanProofDetailsReq";
import { SendApplicationLinkReq } from "../../services/application/models/SendApplicationLinkReq";
import { GetUploadedBankStatementsReq } from "../../services/application/models/GetUploadedBankStatementsReq";
import { ExtractBankStatementDetailsRequest } from "../../services/application/models/ExtractBankStatementDetailsReq";
import { GetUploadedIncomeProofsReq } from "../../services/application/models/GetUploadedIncomeProofsReq";
import { GetUploadedMiscDocsReq } from "../../services/application/models/GetUploadedMiscDocsReq";

export const actions = {
    FETCH_APPLICATION_BY_QUOTE: 'FETCH_APPLICATION_BY_QUOTE',
    FETCH_PAN_DETAILS: 'FETCH_PAN_DETAILS',
    FETCH_PINCODE_DETAILS: 'FETCH_PINCODE_DETAILS',
    UPDATE_APPLICANT: 'UPDATE_APPLICANT',
    UPDATE_BANK_ACCOUNT_DETAILS: 'UPDATE_BANK_ACCOUNT_DETAILS',
    EXTRACT_ADDRESS_PROOF_DETAILS: 'EXTRACT_ADDRESS_PROOF_DETAILS',
    EXTRACT_PAN_PROOF_DETAILS: 'EXTRACT_PAN_PROOF_DETAILS',
    VERIFY_UPDATE_ADDRRESS_PROOF_DETAILS: "VERIFY_UPDATE_ADDRRESS_PROOF_DETAILS",
    SEND_APPLICATION_LINK: "SEND_APPLICATION_LINK",
    FETCH_UPLOADED_BANK_STATEMENTS: "FETCH_UPLOADED_BANK_STATEMENTS",
    EXTRACT_BANK_STATEMENT_DETAILS: "EXTRACT_BANK_STATEMENT_DETAILS",
    FETCH_IFSC_DETAILS: "FETCH_IFSC_DETAILS",
    FETCH_UPLOADED_INCOME_PROOFS: "FETCH_UPLOADED_INCOME_PROOFS",
    FETCH_UPLOADED_MISC_DOCS: "FETCH_UPLOADED_MISC_DOCS",
};

export const fetchApplicationByQuote = createAction<{ Reference: string, ReferenceType: "quote" | "insta" }>(
    actionRequest(actions.FETCH_APPLICATION_BY_QUOTE),
);

export const fetchPanDetails = createAction<GetPanDetailsRequest>(
    actionRequest(actions.FETCH_PAN_DETAILS),
);

export const fetchPincodeDetails = createAction<GetPincodeDetailsRequest>(
    actionRequest(actions.FETCH_PINCODE_DETAILS),
);

export const updateApplicant = createAction<ApplicantUpdateReq>(
    actionRequest(actions.UPDATE_APPLICANT),
)

export const updateBankAccountDetails = createAction<UpdateBankAccountDetailsReq>(
    actionRequest(actions.UPDATE_BANK_ACCOUNT_DETAILS),
)

export const extractAddressProofDetails = createAction<ExtractedAddressProofDetailsReq>(
    actionRequest(actions.EXTRACT_ADDRESS_PROOF_DETAILS),
)

export const verifyUpdateAddressProofDetails = createAction<VerifyUpdateAddressDetailsReq>(
    actionRequest(actions.VERIFY_UPDATE_ADDRRESS_PROOF_DETAILS),
)

export const extractPanProofDetails = createAction<ExtractedPanProofDetailsReq>(
    actionRequest(actions.EXTRACT_PAN_PROOF_DETAILS),
)

export const sendApplicationLink = createAction<SendApplicationLinkReq>(
    actionRequest(actions.SEND_APPLICATION_LINK),
)

export const fetchBankStatements = createAction<GetUploadedBankStatementsReq>(
    actionRequest(actions.FETCH_UPLOADED_BANK_STATEMENTS),
)

export const extractBankStatementDetails = createAction<ExtractBankStatementDetailsRequest>(
    actionRequest(actions.EXTRACT_BANK_STATEMENT_DETAILS),
)

export const fetchIfscDetails = createAction<string>(
    actionRequest(actions.FETCH_IFSC_DETAILS),
)

export const fetchUploadedIncomeProofs = createAction<GetUploadedIncomeProofsReq>(
    actionRequest(actions.FETCH_UPLOADED_INCOME_PROOFS),
)

export const fetchUploadedMiscDocs = createAction<GetUploadedMiscDocsReq>(
    actionRequest(actions.FETCH_UPLOADED_MISC_DOCS),
)