import { createReducer } from '@reduxjs/toolkit';
import { actions as types } from './actions';
import { actionSuccess } from '../helper';
import Authenticator from '../../utils/authenticator';

export interface State
{
    sessionToken: string | null;
}

const initialState: State = {
    sessionToken: Authenticator.getToken()
};

export default createReducer(initialState, {
    [actionSuccess(types.SESSION_LOGIN)]: (state, action) =>
    {
        state.sessionToken = action.payload;
    },
    [types.SESSION_LOGOUT]: (state, action) =>
    {
        state.sessionToken = null;
    },
});
