import
{
    actions as types,
} from './actions';
import { Middleware } from 'redux';
import { actionSuccess } from '../helper';

export const sessionExpiredFlow: Middleware = ({ dispatch }) => (next) => (
    action,
) =>
{
    next(action);
    if (
        action.type === types.SESSION_EXPIRED ||
        action.type === types.UNAUTHORIZED_ACCESS
    )
    {
        dispatch({
            type: "SESSION_LOGOUT",
        });
    }
};


export default [
    sessionExpiredFlow
];
