import { ENV } from '../env.json';

const colorSet = {
  primary: ENV === 'prod' ? '#4D4DDE' : '#F02971',
  primaryDark: ENV === 'prod' ? '#4545C8' : '#D21C5E',
};

const Colors = {
  white: '#FFF',
  black: '#000',
  backgroundColor: '#f1f3f6',
  borderColor: '#E9E9E9',
  borderColorDark: '#D4D4D4',
  primaryColor: colorSet.primary,
  primaryColorLight: '#E4E4FA',
  primaryColorDark: colorSet.primaryDark,
  header: {
    light: '#fff',
    dark: colorSet.primary,
  },
  status: {
    error: '#EB5757',
    success: '#27AE60',
    info: colorSet.primary,
    warning: '#FFCF3C',
  },
  icon: {
    default: '#000',
    selected: colorSet.primary,
  },
  form: {
    error: 'red',
  },
  alert: {
    success: '#5cb85c',
    info: '#5bc0de',
    warning: '#f0ad4e',
    danger: '#d9534f',
  },
  buttons: {
    danger: '#EB5757',
    light: '#ECECEC',
    lighText: '#20222E',
    primary: colorSet.primary,
    primaryText: '#FFF',
    action: '#F56138',
    actionText: '#FFF',
    disabled: '#B3B5C0',
  },
  text: {
    label: '#666',
    inactive: '#B3B5C0',
    white: '#fff',
    placeholder: '#bbb',
    black: '#000000',
  },
};
export default Colors;
