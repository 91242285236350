const authentication = 'https://pp.propelld.com/api/api-gateway/propelld/sdk/auth';
const application = 'https://pp.propelld.com/api/api-gateway/propelld/sdk/application';
const staticdata = 'https://pp.propelld.com/api/api-gateway/propelld/sdk/static';
const documents = 'https://pp.propelld.com/api/resources';
const staticData = 'https://pp.propelld.com/api/'
const baseUrls = {
    authentication,
    application,
    staticdata,
    documents,
    staticData
};

export default baseUrls;
