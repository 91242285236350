const authentication = 'http://localhost:8080/api-gateway/propelld/sdk/auth';
const application = 'http://localhost:8080/api-gateway/propelld/sdk/application';
const staticdata = 'http://localhost:8080/api-gateway/propelld/sdk/static';
const documents = 'http://localhost:8080/resources';
const staticData = 'http://localhost:4050/';
const baseUrls = {
    authentication,
    application,
    staticdata,
    documents,
    staticData
};

export default baseUrls;
