import React, { useEffect, useState } from "react";
import useApplication from "../../../hooks/applicationHook";
import { Button, CircularProgress } from "@material-ui/core";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import {
	sendApplicationLink,
	actions,
	fetchApplicationByQuote,
} from "../../../store/application/actions";
import useActionLoader from "../../../hooks/actionLoadersHook";
import "./style.scss";
import { removeDoneActions } from "../../../store/ui/actions";
import { useParams } from "react-router-dom";
import useDoneAction from "../../../hooks/doneActionHook";
import BankStatementAlertModal from "./BankStatementAlertModal";
import baseUrls from "../../../config/service";
import axios from "axios";
import Authenticator from "../../../utils/authenticator";
import { RootState } from "../../../config/store";

const mapStateToProps = (state: RootState) => {
	return {
		uploadedBS: state.application.uploadedBS,
	};
};

const connector = connect(mapStateToProps, {});

type ReduxProps = ConnectedProps<typeof connector>;

const SendLinkRoot: React.FC<ReduxProps> = (props) => {
	const { id } = useParams<any>();
	const { application, applicationHelper } = useApplication();
	const dispatch = useDispatch();
	const actionLoader = useActionLoader();
	const doneActions = useDoneAction();
	const [openModal, setOpenModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const refreshApplication = () => {
		dispatch(
			fetchApplicationByQuote({
				Reference: id,
				ReferenceType: "quote",
			})
		);
	};

	useEffect(() => {
		if (doneActions[actions.SEND_APPLICATION_LINK]) {
			refreshApplication();
			dispatch(removeDoneActions([actions.SEND_APPLICATION_LINK]));
		}
	}, [doneActions[actions.SEND_APPLICATION_LINK]]);

	let showSendLinkCard = applicationHelper.isApplicantDetailsCompleted();

	const removeUploadedBankStatements = async () => {
		const uploadedFiles = props.uploadedBS
			? application?.CoBorrowerRequired
				? props.uploadedBS.documents?.CoApplicants.map((item) => ({
						resourceId: item.ResourceId,
				  })) ?? []
				: props.uploadedBS.documents?.Applicants.map((item) => ({
						resourceId: item.ResourceId,
				  })) ?? []
			: [];
		await Promise.all(
			uploadedFiles.map(
				async (e) =>
					await axios(
						baseUrls.documents +
							"/websdk/application/document/remove",
						{
							method: "POST",
							headers: {
								"x-access-token":
									Authenticator.getToken() || "",
							},
							data: {
								InstaId: application?.CreationReference,
								ResourceId: e.resourceId,
							},
						}
					)
			)
		);
	};

	const onSendLink = () => { 
		if (!application?.Flags?.IsCounsellorSentLinkToCustomer) {
			if (application?.CoBorrowerRequired) {
				if (application?.BankStatementDocument?.CoApplicant?.Error) {
					setOpenModal(true);
					setErrorMessage(
						application?.BankStatementDocument?.CoApplicant?.Error
							.Message
					);
					return;
				}
			}

			if (!application?.CoBorrowerRequired) {
				if (!!application?.BankStatementDocument?.Applicant?.Error) {
					setOpenModal(true);
					setErrorMessage(
						application?.BankStatementDocument?.Applicant?.Error
							.Message
					);
					return;
				}
			}
		}

		dispatch(
			sendApplicationLink({
				ApplicationCreationReference: application?.CreationReference!,
			})
		);
	};

	const isCounsellorSentLinkToCustomer =
		application?.Flags?.IsCounsellorSentLinkToCustomer;

	if (!showSendLinkCard) {
		return <></>;
	} else
		return (
			<div className="loan-step-content__send-link">
				<div className="title">
					Consent{" "}
					{isCounsellorSentLinkToCustomer ? "Pending!" : "Required!"}
				</div>
				<div className="desc">
					Consent of Loan Application is{" "}
					{isCounsellorSentLinkToCustomer ? "pending" : "required"}{" "}
					from Customer.
					{isCounsellorSentLinkToCustomer
						? ""
						: "Please share the link by clicking below"}
				</div>
				<div className="action">
					<Button
						fullWidth
						variant="contained"
						color="primary"
						size="large"
						onClick={onSendLink}
						startIcon={
							actionLoader[actions.SEND_APPLICATION_LINK] ? (
								<CircularProgress size="1rem" />
							) : undefined
						}
						disabled={actionLoader[actions.SEND_APPLICATION_LINK]}
                        data-test-id="share-link__btn"
					>
						{isCounsellorSentLinkToCustomer ? "Resend" : "Share"}{" "}
						Link with Applicant
					</Button>
					<BankStatementAlertModal
						showModal={openModal}
						onNo={() => setOpenModal(false)}
						onYes={async() => {
							setOpenModal(false);
                            await removeUploadedBankStatements();
							dispatch(
								sendApplicationLink({
									ApplicationCreationReference:
										application?.CreationReference!,
								})
							);
						}}
						errorMessage={errorMessage}
					/>
				</div>
			</div>
		);
};

export default connector(SendLinkRoot);
