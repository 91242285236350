import React from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import "./style.result-comp.scss";

type Props = {
	title: string;
	subTitle: string;
	status:
		| "success"
		| "warn"
		| "loading"
		| "error"
		| "info"
		| "warning"
		| "404"
		| "403"
		| "500";
	extra?: any;
    testId: string;
};

const Result: React.FC<Props> = (props) => {
	const { title, subTitle, status, extra } = props;
	let classFix = "result-comp--default";
	let _icon = <CheckCircleIcon style={{ fontSize: "48px" }} />;

	if (status === "success") {
		classFix = "result-comp--success";
		_icon = <CheckCircleIcon style={{ fontSize: "48px" }} />;
	}
	
	return (
		<div className={`result-comp ${classFix}`} data-test-id={props.testId}>
			<div className="icon">{_icon}</div>
			<div className="title">{title}</div>
			<div className="sub-title">{subTitle}</div>
			<div className="extra">{extra}</div>
		</div>
	);
};

export default Result;
