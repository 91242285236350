import React from "react";
import NGrid from "../../../components/atoms/NGrid";
import Spacer from "../../../components/atoms/Spacer";
import TextView from "../../../components/atoms/TextView";
import "./details.scss";
import { LoanDetailsCardModel } from "./models/LoanDetailsCardModel";
import { formatWithCurrency } from "../../../utils/helpers";

type Props = {
	data: LoanDetailsCardModel;
};

const DetailsReadOnly: React.FC<Props> = (props) => {
	return (
		<div className="details-readonly-container">
			<NGrid.Row>
				<NGrid.Col>
					<TextView
						label="Institute"
						value={props.data.InstituteName}
					/>
				</NGrid.Col>
			</NGrid.Row>
			{/* <NGrid.Row>
				<NGrid.Col>
					<TextView label="Course" value={props.data.CourseName} />
				</NGrid.Col>
			</NGrid.Row> */}
			<NGrid.Row>
				<NGrid.Col>
					<TextView
						label="Discounted Course Fee"
						value={formatWithCurrency(
							props.data.DiscountedCourseFee
						)}
					/>
				</NGrid.Col>
			</NGrid.Row>
			<Spacer h={1} border />
			<NGrid.Row>
				<NGrid.Col>
					<TextView
						label="Loan Amount"
						value={formatWithCurrency(props.data.LoanAmount)}
					/>
				</NGrid.Col>
				<NGrid.Col>
					<TextView
						label="Tenure"
						value={props.data.Tenure + " Months"}
					/>
				</NGrid.Col>
			</NGrid.Row>
			<NGrid.Row>
				{props.data.Moratorium > 0 && (
					<NGrid.Col>
						<TextView
							label="Moratorium"
							value={props.data.Moratorium + " Months"}
						/>
					</NGrid.Col>
				)}
				{props.data.NoOfAdvanceEmi > 0 && (
					<NGrid.Col>
						<TextView
							label="Advance EMI"
							value={props.data.NoOfAdvanceEmi + ""}
						/>
					</NGrid.Col>
				)}
			</NGrid.Row>
		</div>
	);
};

export default DetailsReadOnly;
