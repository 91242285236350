// ApiUtils.js
import HttpStatusCode from "../models/HttpStatusCodes";
import ApiErrorHelper from "../models/ApiErrorHelper";
import { ServiceResponse } from "../models/ServiceResponse";

export let checkStatus = (response: Response) =>
{
    if (
        [
            HttpStatusCode.OK,
            HttpStatusCode.NOT_MODIFIED,
            HttpStatusCode.INTERNAL_SERVER_ERROR,
        ].includes(response.status)
    )
    {
        return response.json();
    }

    throw ApiErrorHelper.getErrorFromNetworkError(response);
};

export let checkResponseCode = (response: ServiceResponse) =>
{
    if (response.Code === "SUCCESS")
    {
        return response;
    } else
    {
        throw ApiErrorHelper.getErrorFromServiceError(response);
    }
};
