import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const _tFormat = (timestamp: number, format: string = 'DD-MM-YYYY') =>
{
    return dayjs.unix(timestamp).format(format);
};


export const dateFormat = (date: string, format: string = 'DD-MM-YYYY') =>
{
    return dayjs(date, "YYYY-MM-DD").format(format);
};
