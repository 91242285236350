import { useSelector } from 'react-redux';
import { RootState } from '../config/store';
import { ApplicationHelper } from '../pages/Application/Helpers/ApplicationHelper';

function useApplication()
{
    const applicationState = useSelector((state: RootState) => state.application);

    const applicationHelper = new ApplicationHelper(applicationState.applicationCreation);

    return {
        ...applicationState, applicationHelper: applicationHelper,
        application: applicationState.applicationCreation
    };
}

export default useApplication;
