import React from "react";
import useApplication from "../../../hooks/applicationHook";
import CollapsibleCard from "../components/CollapsibleCard";
import ApplicantReadOnly from "./Applicant.ReadOnly";
import DocumentCropper from "../../../components/atoms/DocumentCropper";
import ApplicantRlpForm from "./Applicant.RLP.Form";
import { ApplicantMapper } from "./ApplicantMapper";
import ApplicantULPForm from "./Applicant.ULP.Form";

const ApplicantRoot: React.FC = (props) => {
	const { application } = useApplication();

	const defaultRLP = ApplicantMapper.MapRLPDefaults(
		application?.PersonalDetails?.Applicant.Details!
	);

	const defaultULP = ApplicantMapper.MapULPDefaults(
		application?.PersonalDetails?.Applicant.Details!
	);

	let isApplicantCardCollapsed = false;
	let isApplicantCardEditable = false;
	let isApplicantCardOnlyEditable = false;
	let isApplicantCardInEditing = false;

	if (application?.PersonalDetails?.Applicant.Captured) {
		isApplicantCardCollapsed = true;
		isApplicantCardEditable = true;
		isApplicantCardInEditing = false;
	} else {
		isApplicantCardOnlyEditable = true;
		isApplicantCardInEditing = true;
	}

	return (
		<>
			<CollapsibleCard
				title="Applicant Details"
				desc="Applicant personal information"
                testId="applicant-details__collapsible-card"
				isCollapsed={isApplicantCardCollapsed}
				isLocked={false}
				showEdit={isApplicantCardEditable}
				defaultIsEditing={isApplicantCardInEditing}
				onlyEditable={isApplicantCardOnlyEditable}
				onToggleCollapse={() => {}}
				renderReadOnlyView={
					<ApplicantReadOnly
						data={application?.PersonalDetails?.Applicant.Details!}
					/>
				}
				renderFormView={
					application?.Flags?.IsULP ? (
						<ApplicantULPForm defaults={defaultULP} />
					) : (
						<ApplicantRlpForm defaults={defaultRLP} />
					)
				}
			/>

			{/* <CollapsibleCard
				title="Bank Account Details"
				desc="Details about your course and loan"
				isCollapsed={true}
				isLocked={false}
				onToggleCollapse={() => {}}
				renderReadOnlyView={<DocumentCropper title="Address Proof" />}
				renderFormView={<></>}
			/> */}
		</>
	);
};

export default ApplicantRoot;
