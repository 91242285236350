import api from './api/middleware';
import ui from './ui/middleware';
import app from './app/middleware';
import auth from './auth/middleware';
import application from './application/middleware';

const rootmiddleware = [
    ...app,
    ...auth,
    ...application,
    ...api,
    ...ui
];

export default rootmiddleware;
