import React from "react";
import NGrid from "../../../components/atoms/NGrid";
import Spacer from "../../../components/atoms/Spacer";
import TextView from "../../../components/atoms/TextView";
import { dateFormat } from "../../../utils/dateUtil";
import { GetCurrentFullAddress } from "../Helpers/AddressHelper";
import { GetGenderLabel } from "../Helpers/GenderHelper";
import "./details.scss";
import { ApplicantCardModel } from "./models/ApplicantCardModel";

type Props = {
	data: ApplicantCardModel;
};

const ApplicantReadOnly: React.FC<Props> = (props) => {
	return (
		<div className="details-readonly-container">
			<NGrid.Row>
				<NGrid.Col>
					<TextView label="PAN" value={props.data.PANNumber} />
				</NGrid.Col>
			</NGrid.Row>
			<NGrid.Row>
				<NGrid.Col>
					<TextView label="Name" value={props.data.FullName} />
				</NGrid.Col>
			</NGrid.Row>
			<NGrid.Row>
				<NGrid.Col>
					<TextView
						label="Father's Name"
						value={props.data.FathersName}
					/>
				</NGrid.Col>
			</NGrid.Row>
			<NGrid.Row>
				<NGrid.Col>
					<TextView
						label="Date Of Birth"
						value={dateFormat(props.data.DateOfBirth)}
					/>
				</NGrid.Col>
				<NGrid.Col>
					<TextView
						label="Gender"
						value={GetGenderLabel(props.data.Gender)}
					/>
				</NGrid.Col>
			</NGrid.Row>
			<Spacer h={1} border />
			<NGrid.Row>
				<NGrid.Col>
					<TextView
						label="Current Address"
						value={GetCurrentFullAddress(props.data)}
					/>
				</NGrid.Col>
			</NGrid.Row>
		</div>
	);
};

export default ApplicantReadOnly;
