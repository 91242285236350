import ui from './ui/reducer';
import app from './app/reducer';
import auth from './auth/reducer';
import application from './application/reducer';

const rootReducer = {
    app,
    ui,
    auth,
    application
};

export default rootReducer;
