export const actions = {
  SHOW_SPINNER: 'SHOW_SPINNER',
  HIDE_SPINNER: 'HIDE_SPINNER',

  SHOW_ERROR: 'SHOW_ERROR',
  REMOVE_ERRORS: 'REMOVE_ERRORS',

  ADD_DONE_ACTIONS: 'ADD_DONE_ACTIONS',
  REMOVE_DONE_ACTIONS: 'REMOVE_DONE_ACTIONS',
};

export const showSpinner = (context = 'global') => ({
  type: actions.SHOW_SPINNER,
  payload: { context },
});

export const hideSpinner = (idxs?: any) => ({
  type: actions.HIDE_SPINNER,
  payload: idxs,
});

export const showError = (
  errType?: any,
  message?: any,
  description?: any,
  context = 'global',
  payload?: any,
) => ({
  type: actions.SHOW_ERROR,
  payload: { errType, message, description, context, payload },
});

export const removeErrors = (idxs: string[]) => ({
  type: actions.REMOVE_ERRORS,
  payload: idxs,
});

export const addDoneActions = (action: any) => ({
  type: actions.ADD_DONE_ACTIONS,
  payload: action,
});

export const removeDoneActions = (indexes: string[]) => ({
  type: actions.REMOVE_DONE_ACTIONS,
  payload: indexes,
});

export default actions;
