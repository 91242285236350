import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

type Props = {
	showModal: boolean;
	errorMessage: string;
	onYes: Function;
	onNo: Function;
};

export default function BankStatementAlertModal(props: Props) {
	return (
			<Dialog
				open={props.showModal}
				onClose={() => props.onNo()}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Do you still want to Share Link with Applicant?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Error in Bank Statement Upload - {props.errorMessage}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button data-test-id="bank-statement-error-alert__no-btn" onClick={() => props.onNo()} color="primary">
						No
					</Button>
					<Button
						onClick={() => props.onYes()}
						color="primary"
						autoFocus
                        data-test-id="bank-statement-error-alert__yes-btn"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
	);
}
