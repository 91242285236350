import React from "react";
import Colors from "../../../theme/Color";

type SpacerProps = {
	w?: string | number;
	h?: string | number;
	border?: boolean;
};

const Spacer: React.FC<SpacerProps> = (props) => {
	const vStyle: React.CSSProperties = {
		height: props.h + "px",
		width: props.w + "px",
		borderBottom: props.border ? "1px solid " + Colors.borderColor : "",
	};

	return <div style={vStyle} />;
};

Spacer.defaultProps = {
	h: "100%",
	w: "100%",
};

export default Spacer;
