import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducers from '../../store/reducers';
import middlewares from '../../store/midlewares';

export const store = configureStore({
    reducer: reducers,
    middleware: [...middlewares, ...getDefaultMiddleware()],
});

export type RootState = ReturnType<typeof store.getState>;
