const TOKEN_ID = 'auth_token';

class Authenticator {
  static isLoggedIn = () => {
    return sessionStorage.getItem(TOKEN_ID);
  };

  static getToken = () => {
    return sessionStorage.getItem(TOKEN_ID);
  };

  static login = (jwt: string) => {
    return sessionStorage.setItem(TOKEN_ID, jwt);
  };

  static logOut = () => {
    return sessionStorage.removeItem(TOKEN_ID);
  };
}

export default Authenticator;
