import { ApplicantEntity } from "../../../services/application/models/ApplicantEntity";
import { CoApplicantEntity } from "../../../services/application/models/CoApplicantEntity";

export const GetCurrentFullAddress = (data: ApplicantEntity | CoApplicantEntity) =>
{
    return data.CurrentAddressLine1 + ", "
        + data.CurrentAddressLine2 + ", "
        + data.CurrentAddressCity + ", "
        + data.CurrentAddressState + " - "
        + data.CurrentAddressPincode
}