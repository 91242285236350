import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { FiChevronDown, FiChevronUp, FiLock, FiX } from "react-icons/fi";
import "./style.scss";

type Props = {
	title: string;
	desc: string;
	isCollapsed: boolean;
	isLocked: boolean;
	onToggleCollapse: () => void;
	showEdit?: boolean;
	onlyEditable?: boolean;
	defaultIsEditing?: boolean;
	renderReadOnlyView: React.ReactNode;
	renderFormView: React.ReactNode;
    testId: string;
};

const CollapsibleCard: React.FC<Props> = (props) => {
	const [isEditing, setIsEditing] = useState(props.defaultIsEditing || false);
	const [isCollapsed, setIsCollapsed] = useState<boolean | null>(null);

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isCollapsed === null) return;
		if (ref.current) {
			if (!isCollapsed) {
				ref.current!.style.height = `${ref.current.scrollHeight}px`;
			} else {
				ref.current!.style.height = "0px";
			}
		}
	}, [isCollapsed]);

	useEffect(() => {
		setTimeout(() => {
			setIsCollapsed(props.isCollapsed);
		}, 300);
	}, []);

	const onIconClicked = () => {
		if (isEditing) {
			setIsEditing(false);
			return;
		}
		if (props.isLocked) {
			return;
		}

		if (!isCollapsed)
			ref.current!.style.height = `${ref.current!.scrollHeight}px`;
		setTimeout(() => {
			setIsCollapsed(!isCollapsed);
		}, 100);
	};

	const onToggleCollapse = () => {
		if (isEditing) {
			return;
		}
		if (props.isLocked) {
			return;
		}

		if (!isCollapsed)
			ref.current!.style.height = `${ref.current!.scrollHeight}px`;

		setTimeout(() => {
			setIsCollapsed(!isCollapsed);
		}, 100);
	};

	const onEditClicked = () => {
		setIsCollapsed(false);
		setIsEditing(true);
	};

	return (
		<div className="collapsible-card-container" data-test-id={props.testId}>
			<div className="container__header">
				<div className="header__left" onClick={onToggleCollapse}>
					<div className="header__title">{props.title}</div>
					<div className="header__desc">{props.desc}</div>
				</div>
				<div className="header_right">
					{props.showEdit && !isEditing && (
						<button onClick={onEditClicked} data-test-id="edit-btn" className="edit-btn">
							EDIT
						</button>
					)}

					{isEditing && !props.onlyEditable && (
						<div className="toggle_icon" data-test-id="toggle_icon" onClick={onIconClicked}>
							<FiX />
						</div>
					)}
					{props.isLocked && (
						<div className="toggle_icon" data-test-id="toggle_icon" onClick={onIconClicked}>
							<FiLock />
						</div>
					)}
					{props.isLocked || isEditing ? null : isCollapsed == null ||
					  isCollapsed ? (
						<div className="toggle_icon" data-test-id="toggle_icon" onClick={onIconClicked}>
							<FiChevronDown />
						</div>
					) : (
						<div className="toggle_icon" data-test-id="toggle_icon" onClick={onIconClicked}>
							<FiChevronUp />
						</div>
					)}
				</div>
			</div>
			<div
				ref={ref}
				onTransitionEnd={(e) => {
					if (!isCollapsed) {
						setTimeout(() => {
							ref.current!.style.height = "inherit";
						}, 100);
					}
				}}
				className={cx("container__content")}
			>
				{isEditing && props.renderFormView}
				{!isEditing && props.renderReadOnlyView}
			</div>
		</div>
	);
};

CollapsibleCard.defaultProps = {
	showEdit: false,
	defaultIsEditing: false,
	onlyEditable: false,
};

export default CollapsibleCard;
