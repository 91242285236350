import { Application, ApplicationCreation } from "../../../services/application/models/Application";
import { LoanDetailsCardModel } from "./models/LoanDetailsCardModel";

export class Mapper
{
    static MapLoanDetailsCardModel(data: ApplicationCreation): LoanDetailsCardModel
    {
        return {
            InstituteName: data.LoanDetails.InstituteName,
            CourseName: data.LoanDetails.CourseName,
            DiscountedCourseFee: data.LoanDetails.DiscountedCourseFee,
            LoanAmount: data.LoanDetails.LoanAmount,
            Tenure: data.LoanDetails.Tenor,
            Moratorium: data.LoanDetails.Moratorium,
            NoOfAdvanceEmi: 0,
            ProcessingFee: 0,
            EMI: undefined
        }
    }
}