import { Middleware } from "@reduxjs/toolkit";
import Authenticator from "../../utils/authenticator";
import { actionSuccess } from "../helper";
import { actions as types } from './actions';

export const sessionLoginFlow: Middleware = ({ dispatch }) => (next) => (
    action,
) =>
{
    next(action);

    if (action.type === types.SESSION_LOGIN)
    {
        Authenticator.login(action.payload);
        dispatch({
            type: actionSuccess(types.SESSION_LOGIN),
            payload: action.payload
        });
    }
};

export default [sessionLoginFlow]
