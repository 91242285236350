import { actions } from './actions';
import { createReducer } from '@reduxjs/toolkit';

const initState = {
  lang: 'en',
};

export default createReducer(initState, {
  [actions.UPDATE_APP_LANGUAGE_SUCCESS]: (state, action) => {
    state.lang = action.payload;
  },
});
