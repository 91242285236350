import { ApplicantEntity } from "../../../services/application/models/ApplicantEntity";
import { ApplicantUpdateReq } from "../../../services/application/models/ApplicantUpdateReq";
import { RLPFormData } from "./models/RLPForm";
import { ULPFormData } from "./models/ULPForm";
import moment from "moment";

export class ApplicantMapper
{
    static MapRlp(data: RLPFormData, id: string): ApplicantUpdateReq
    {
        let dob = moment(data.dateOfBirth).isValid() ? moment(data.dateOfBirth).format("DD/MM/YYYY") : data.dateOfBirth;

        return {
            CreationReference: id,
            IsEarning: true, //data.isEarning == "yes",
            CompanyName: data.companyName,
            EmploymentType: data.employmentType,
            Profession: data.profession,
            Gender: data.gender,
            EarningForYears: data.earningForYears || 0,
            CurrentMonthlyIncome: data.currentlyMonthlyIncome || 0,
            EMIsPaying: data.emisPaying || 0,
            FullName: data.applicantName,
            FathersName: data.fathersName,
            Age: moment(dob).isValid() ? moment().diff(dob, "years") : 0,
            DateOfBirth: data.dateOfBirth!,
            PANNumber: data.pan,
            HasPAN: true,
            CurrentAddressLine1: data.currentAddressLine1,
            CurrentAddressLine2: data.currentAddressLine2,
            CurrentAddressCity: data.currentAddressCity,
            CurrentAddressState: data.currentAddressState.value ? data.currentAddressState.value : data.currentAddressState,
            CurrentAddressPincode: data.currentAddressPincode,
            CurrentAddressCountry: "India"
        }
    }

    static MapUlp(data: ULPFormData, id: string): ApplicantUpdateReq
    {
        let dob = moment(data.dateOfBirth).isValid() ? moment(data.dateOfBirth).format("DD/MM/YYYY") : data.dateOfBirth;

        return {
            CreationReference: id,
            IsEarning: false,
            CompanyName: "",
            EmploymentType: "",
            Profession: "",
            Gender: data.gender,
            EarningForYears: 0,
            CurrentMonthlyIncome: 0,
            EMIsPaying: 0,
            FullName: data.applicantName,
            FathersName: data.fathersName,
            Age: moment(dob).isValid() ? moment().diff(dob, "years") : 0,
            DateOfBirth: data.dateOfBirth!,
            PANNumber: data.pan,
            HasPAN: true,
            CurrentAddressLine1: data.currentAddressLine1,
            CurrentAddressLine2: data.currentAddressLine2,
            CurrentAddressCity: data.currentAddressCity,
            CurrentAddressState: data.currentAddressState.value ? data.currentAddressState.value : data.currentAddressState,
            CurrentAddressPincode: data.currentAddressPincode,
            CurrentAddressCountry: "India"
        }
    }

    static MapRLPDefaults(data: ApplicantEntity): RLPFormData
    {
        if (!data)
        {
            return {
                pan: "",
                applicantName: "",
                fathersName: "",
                dateOfBirth: undefined,
                gender: "",
                isEarning: "yes",
                employmentType: "",
                profession: "",
                earningForYears: undefined,
                companyName: "",
                currentAddressPincode: "",
                currentAddressLine1: "",
                currentAddressLine2: "",
                currentAddressCity: "",
                currentAddressState: "",
            }
        }
        else
            return {
                pan: data.PANNumber || "",
                applicantName: data.FullName || "",
                fathersName: data.FathersName || "",
                dateOfBirth: data.DateOfBirth || undefined,
                gender: data.Gender || "",
                employmentType: data.EmploymentType,
                profession: data.Profession || "",
                earningForYears: data.EarningForYears || undefined,
                currentlyMonthlyIncome: data.CurrentMonthlyIncome || undefined,
                emisPaying: data.EMIsPaying || undefined,
                isEarning: data.IsEarning ? "yes" : "no" || undefined,
                companyName: data.CompanyName,
                currentAddressPincode: data.CurrentAddressPincode + "" || "",
                currentAddressLine1: data.CurrentAddressLine1 || "",
                currentAddressLine2: data.CurrentAddressLine2 || "",
                currentAddressCity: data.CurrentAddressCity || "",
                currentAddressState: data.CurrentAddressState || "",
            }
    }

    static MapULPDefaults(data: ApplicantEntity): ULPFormData
    {
        if (!data)
        {
            return {
                pan: "",
                applicantName: "",
                fathersName: "",
                dateOfBirth: undefined,
                gender: "",
                currentAddressPincode: "",
                currentAddressLine1: "",
                currentAddressLine2: "",
                currentAddressCity: "",
                currentAddressState: "",
            }
        }
        else
            return {
                pan: data.PANNumber || "",
                applicantName: data.FullName || "",
                fathersName: data.FathersName || "",
                dateOfBirth: data.DateOfBirth || undefined,
                gender: data.Gender || "",
                currentAddressPincode: data.CurrentAddressPincode + "" || "",
                currentAddressLine1: data.CurrentAddressLine1 || "",
                currentAddressLine2: data.CurrentAddressLine2 || "",
                currentAddressCity: data.CurrentAddressCity || "",
                currentAddressState: data.CurrentAddressState || "",
            }
    }
}