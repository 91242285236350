import { NetworkRequest } from '../models/NetworkRequest';
import NetworkRequestHelper from '../helper';
import baseUrl from '../../config/service';
import { GetPanDetailsRequest } from './models/GetPanDetailsRequest';
import { GetPincodeDetailsRequest } from './models/GetPincodeDetailsRequest';
import { ApplicantUpdateReq } from './models/ApplicantUpdateReq';
import { UpdateBankAccountDetailsReq } from './models/UpdateBankAccountDetailsReq';
import { ExtractedAddressProofDetailsReq } from './models/ExtractedAddressProofDetailsReq';
import { VerifyUpdateAddressDetailsReq } from './models/VerifyUpdateAddressDetailsReq';
import { ExtractedPanProofDetailsReq } from './models/ExtractedPanProofDetailsReq';
import { SendApplicationLinkReq } from './models/SendApplicationLinkReq';
import { GetUploadedBankStatementsReq } from './models/GetUploadedBankStatementsReq';
import { GetUploadedIncomeProofsReq } from './models/GetUploadedIncomeProofsReq';

class ApplicationService
{
    static getApplicationByQuoteId = (data: { Reference: string, ReferenceType: "quote" | "insta" }): NetworkRequest =>
    {
        return NetworkRequestHelper.POST(baseUrl.application + '/get', {
            Reference: data.Reference,
            ReferenceType: data.ReferenceType
        });
    };

    static getPanDetailsForApplication = (data: GetPanDetailsRequest): NetworkRequest =>
    {
        return NetworkRequestHelper.POST(baseUrl.application + '/pan-details-fetch', data);
    };

    static getPincodeDetailsForApplication = (data: GetPincodeDetailsRequest): NetworkRequest =>
    {
        return NetworkRequestHelper.POST(baseUrl.application + '/pincode-details-fetch', data);
    };

    static updateApplicant = (data: ApplicantUpdateReq): NetworkRequest =>
    {
        return NetworkRequestHelper.POST(baseUrl.application + '/applicant/update', data);
    };

    static updateBankAccountDetails = (data: UpdateBankAccountDetailsReq): NetworkRequest =>
    {
        return NetworkRequestHelper.POST(baseUrl.application + '/bank-details/update', data);
    };

    static extractAddressProofDetails = (data: ExtractedAddressProofDetailsReq): NetworkRequest =>
    {
        return NetworkRequestHelper.POST(baseUrl.application + '/address-proof/details/extract', data);
    };

    static verifyUpdateAddressDetails = (data: VerifyUpdateAddressDetailsReq): NetworkRequest =>
    {
        return NetworkRequestHelper.POST(baseUrl.application + '/address-proof/details/verify-update', data);
    };

    static extractPanProofDetails = (data: ExtractedPanProofDetailsReq): NetworkRequest =>
    {
        return NetworkRequestHelper.POST(baseUrl.application + '/pan-proof/details/extract', data);
    };

    static sendApplicationLink = (data: SendApplicationLinkReq): NetworkRequest =>
    {
        return NetworkRequestHelper.POST(baseUrl.application + '/send-link', data);
    };

    static getUploadedBankStatement = (data: GetUploadedBankStatementsReq): NetworkRequest =>
    {
        return NetworkRequestHelper.POST(baseUrl.application + '/uploaded-documents/bank-statements/get', data);
    };

    static getIfscDetails = (ifsc: string): NetworkRequest =>
    {
        return NetworkRequestHelper.GET(baseUrl.staticdata + '/ifsc/details/get', { ifsc });
    };

    static extractBankStatementDetails = (data: ExtractedPanProofDetailsReq): NetworkRequest =>
    {
        return NetworkRequestHelper.POST(baseUrl.application + '/bank-statement/extract', data);
    };

    static getUploadedIncomeProofs = (data: GetUploadedIncomeProofsReq): NetworkRequest =>
    {
        return NetworkRequestHelper.POST(baseUrl.application + '/uploaded-documents/income-proofs/get', data);
    };

    static getUploadedMiscDocs = (data: GetUploadedIncomeProofsReq): NetworkRequest =>
    {
        return NetworkRequestHelper.POST(baseUrl.application + '/uploaded-documents/misc-docs/get', data);
    };
}

export default ApplicationService;
