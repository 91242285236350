import React from "react";
import { Route, Redirect } from "react-router-dom";

export const RestrictedRoute: React.FC<any> = ({
	component: Component,
	isLoggedIn,
	...rest
}) => (
	<Route
		{...rest}
		render={(props) =>
			isLoggedIn ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: "/authenticate",
						search: props.location.search,
						state: { from: props.location },
					}}
				/>
			)
		}
	/>
);
