import { useSelector } from 'react-redux';
import { RootState } from '../config/store';

function useAuth()
{
    const authState = useSelector((state: RootState) => state.auth);

    return { ...authState, isLoggedIn: !!authState.sessionToken };
}

export default useAuth;
