import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import useAuth from "../../hooks/authHook";
import { sessionLogin } from "../../store/auth/actions";
import Authenticator from "../../utils/authenticator";

const AuthenticationPage: React.FC = () => {
	const dispatch = useDispatch();
	const { search, state } = useLocation<any>();
	const { isLoggedIn } = useAuth();
	const { push } = useHistory();

	useEffect(() => {
		const searchParams = new URLSearchParams(search);
		const token = searchParams.get("session_token");
		const sessionToken = Authenticator.getToken();
		if (token) {
			dispatch(sessionLogin(token));
		} else {
			if (sessionToken) {
				dispatch(sessionLogin(sessionToken));
			}

			push("/error");
		}
	}, []);

	useEffect(() => {
		if (isLoggedIn) {
			let toPath = "/";
			if (state) {
				toPath = state.from.pathname;
			}
			push(toPath, state ? { extra: state.extra } : null);
		}
	}, [isLoggedIn]);

	return <p>Logging Session</p>;
};

export default AuthenticationPage;
