import React, { useEffect } from "react";
import LoanSteps from "./LoanSteps";
import "./ApplicationHome.scss";
import {
	fetchApplicationByQuote,
	actions,
} from "../../store/application/actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import useApplication from "../../hooks/applicationHook";
import useDoneAction from "../../hooks/doneActionHook";
import { removeDoneActions } from "../../store/ui/actions";
import Skeleton from "@material-ui/lab/Skeleton";
import { toast } from "react-toastify";
import { fetchBankStatements } from "../../store/application/actions";

const ApplicationHome: React.FC = (props) => {
	const { id } = useParams<any>();
	const dispatch = useDispatch();
	const { application } = useApplication();
	const doneActions = useDoneAction();

	const refreshApplication = () => {
		dispatch(
			fetchApplicationByQuote({
				Reference: id,
				ReferenceType: "quote",
			})
		);
	};

	useEffect(() => {
		refreshApplication();
	}, []);

	useEffect(() => {
		if (doneActions[actions.FETCH_APPLICATION_BY_QUOTE]) {
			dispatch(
				fetchBankStatements({
					ApplicationCreationReference:
						application?.CreationReference!,
				})
			);
			dispatch(removeDoneActions([actions.FETCH_APPLICATION_BY_QUOTE]));
		}
	}, [doneActions[actions.FETCH_APPLICATION_BY_QUOTE]]);

	// On Applicant Update

	useEffect(() => {
		if (doneActions[actions.UPDATE_APPLICANT]) {
			refreshApplication();
			dispatch(removeDoneActions([actions.UPDATE_APPLICANT]));
		}
	}, [doneActions[actions.UPDATE_APPLICANT]]);

	// On Bank Account Details Update

	useEffect(() => {
		if (doneActions[actions.UPDATE_BANK_ACCOUNT_DETAILS]) {
			refreshApplication();
			dispatch(removeDoneActions([actions.UPDATE_BANK_ACCOUNT_DETAILS]));
		}
	}, [doneActions[actions.UPDATE_BANK_ACCOUNT_DETAILS]]);

	// On Address Details Update

	useEffect(() => {
		if (doneActions[actions.VERIFY_UPDATE_ADDRRESS_PROOF_DETAILS]) {
			refreshApplication();
			dispatch(
				removeDoneActions([
					actions.VERIFY_UPDATE_ADDRRESS_PROOF_DETAILS,
				])
			);
		}
	}, [doneActions[actions.VERIFY_UPDATE_ADDRRESS_PROOF_DETAILS]]);

	// On PAN Verify

	useEffect(() => {
		if (doneActions[actions.SEND_APPLICATION_LINK]) {
			toast.success("Consent Link shared with Applicant!", {
				position: "top-center",
			});
			dispatch(removeDoneActions([actions.SEND_APPLICATION_LINK]));
		}
	}, [doneActions[actions.SEND_APPLICATION_LINK]]);

	if (!application) {
		return (
			<div className="loan-step-content" data-test-id="root__loading-application">
				<Skeleton
					style={{ borderRadius: 8 }}
					height={40}
					animation="wave"
				/>
				<Skeleton
					style={{ borderRadius: 8 }}
					height={80}
					animation="wave"
				/>
				<Skeleton
					style={{ borderRadius: 8 }}
					height={80}
					animation="wave"
				/>
			</div>
		);
	}

	return (
		<div>
			<LoanSteps />
		</div>
	);
};

export default ApplicationHome;
