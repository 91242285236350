import { useSelector } from 'react-redux';
import { RootState } from '../config/store';

function useDoneAction()
{
    const doneActions = useSelector((state: RootState) => state.ui.doneActions);

    return { ...doneActions };
}

export default useDoneAction;
